import React, { PropsWithChildren } from 'react'
import ContentSection from '../../ContentSection/ContentSection'
import { Fab, Tooltip, TooltipProps, Typography } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import PersonasContainer from './PersonasContainer'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

const useTooltipStyles = makeStyles((theme: Theme) =>
    createStyles({
        tooltip: {
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            fontWeight: 700,
        },
        arrow: {
            color: theme.palette.secondary.main,
        },
    })
)

const ThemedTooltip = (props: PropsWithChildren<TooltipProps>) => {
    const { title, children, ...rest } = props
    const classes = useTooltipStyles()

    return (
        <Tooltip title={title} classes={classes} {...rest}>
            {children}
        </Tooltip>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fabWrapper: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        fab: {
            position: 'fixed',
            bottom: theme.spacing(2),
            zIndex: 99,
        },
        research: {
            '& ol > li': {
                marginBottom: theme.spacing(6),
                '&:last-child': {
                    marginBottom: 0,
                },
            },
        },
        context: {
            marginTop: theme.spacing(1),
            '&:not(:first-of-type)': {
                marginTop: theme.spacing(6),
            },
        },
        img: {
            maxWidth: 1200,
            marginTop: theme.spacing(2),
        },
        how: {
            display: 'block',
            fontSize: '0.75rem',
            fontWeight: 400,
            padding: theme.spacing(0.5),
            marginTop: theme.spacing(0.5),
            color: theme.palette.common.black,
            backgroundColor: `#dfdfdf`,
        },
    })
)

const CPC: React.FC = () => {
    const styles = useStyles()
    const data = useStaticQuery(graphql`
        {
            pageTitle: file(relativePath: { eq: "images/cpc/page-title.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1200) {
                        aspectRatio
                        src
                        srcSet
                        sizes
                        base64
                        srcWebp
                        srcSetWebp
                    }
                }
            }
            dreamPc: file(
                relativePath: { eq: "images/cpc/dream-pc-banner.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1200) {
                        aspectRatio
                        src
                        srcSet
                        sizes
                        base64
                        srcWebp
                        srcSetWebp
                    }
                }
            }
            quickScrollMenu: file(
                relativePath: { eq: "images/cpc/quick-scroll-menu.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1200) {
                        aspectRatio
                        src
                        srcSet
                        sizes
                        base64
                        srcWebp
                        srcSetWebp
                    }
                }
            }
            dreamPcMiddlePage: file(
                relativePath: { eq: "images/cpc/dream-pc-to-intermediary.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1200) {
                        aspectRatio
                        src
                        srcSet
                        sizes
                        base64
                        srcWebp
                        srcSetWebp
                    }
                }
            }
            emptyList: file(relativePath: { eq: "images/cpc/empty-list.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1128) {
                        aspectRatio
                        src
                        srcSet
                        sizes
                        base64
                        srcWebp
                        srcSetWebp
                    }
                }
            }
            noPrice: file(relativePath: { eq: "images/cpc/no-price.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1128) {
                        aspectRatio
                        src
                        srcSet
                        sizes
                        base64
                        srcWebp
                        srcSetWebp
                    }
                }
            }
            searchFilters: file(
                relativePath: { eq: "images/cpc/search-filters.png" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1200) {
                        aspectRatio
                        src
                        srcSet
                        sizes
                        base64
                        srcWebp
                        srcSetWebp
                    }
                }
            }
            relativePrice: file(
                relativePath: { eq: "images/cpc/relative-price.png" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1200) {
                        aspectRatio
                        src
                        srcSet
                        sizes
                        base64
                        srcWebp
                        srcSetWebp
                    }
                }
            }
            unitPrice: file(relativePath: { eq: "images/cpc/unit-price.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1200) {
                        aspectRatio
                        src
                        srcSet
                        sizes
                        base64
                        srcWebp
                        srcSetWebp
                    }
                }
            }
            unresponsiveAction: file(
                relativePath: { eq: "images/cpc/unresponsive-action.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1200) {
                        aspectRatio
                        src
                        srcSet
                        sizes
                        base64
                        srcWebp
                        srcSetWebp
                    }
                }
            }
            buttonConsistency: file(
                relativePath: { eq: "images/cpc/button-consistency.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 386) {
                        aspectRatio
                        src
                        srcSet
                        sizes
                        base64
                        srcWebp
                        srcSetWebp
                    }
                }
            }
            noPromoPrice: file(
                relativePath: { eq: "images/cpc/no-promo-price.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1200) {
                        aspectRatio
                        src
                        srcSet
                        sizes
                        base64
                        srcWebp
                        srcSetWebp
                    }
                }
            }
            sebastian: file(
                relativePath: { eq: "images/cpc/Sebastian@2x.png" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 2416, quality: 90) {
                        aspectRatio
                        src
                        srcSet
                        sizes
                        base64
                        srcWebp
                        srcSetWebp
                    }
                }
            }
            adam: file(relativePath: { eq: "images/cpc/Adam@2x.png" }) {
                childImageSharp {
                    fluid(maxWidth: 2416, quality: 90) {
                        aspectRatio
                        src
                        srcSet
                        sizes
                        base64
                        srcWebp
                        srcSetWebp
                    }
                }
            }
            luan: file(relativePath: { eq: "images/cpc/Luan@2x.png" }) {
                childImageSharp {
                    fluid(maxWidth: 2416, quality: 90) {
                        aspectRatio
                        src
                        srcSet
                        sizes
                        base64
                        srcWebp
                        srcSetWebp
                    }
                }
            }
        }
    `)
    return (
        <ContentSection
            container
            title='Custom PC Configurator'
            style={{ marginBottom: 56 }}
        >
            <div className={styles.fabWrapper}>
                <ThemedTooltip
                    title='Open live wireframe'
                    placement='top'
                    arrow
                >
                    <a
                        href='https://salvatoresaitta-cpc-live.netlify.com/'
                        target='_blank'
                        rel='noopener noreferrer'
                        className={styles.fab}
                        style={{ background: 'none' }}
                    >
                        <Fab
                            color='secondary'
                            size='small'
                            aria-label='View live wireframe'
                        >
                            <OpenInNewIcon />
                        </Fab>
                    </a>
                </ThemedTooltip>
            </div>
            <div style={{ margin: '0 -16px' }}>
                <ContentSection container child title='Brief'>
                    <Typography>
                        Mwave Custom PC Configurator is a tool that allow
                        customers to customise and buy a PC based on their
                        needs/preferences. The aim of this research is to find
                        eventual issues that customers might be having with the
                        current version of the tool and use those issues to
                        improve it and build a better tool.{' '}
                        <a
                            href='https://salvatoresaitta-cpc-live.netlify.com/'
                            target='_blank'
                            rel='noopener noreferrer'
                            aria-label='Open live wireframe'
                        >
                            Check out the live wireframe here.
                        </a>
                    </Typography>
                </ContentSection>
                <ContentSection container child title='The Tool'>
                    <Typography>
                        The current tool comprises two pages:
                    </Typography>
                    <ul>
                        <li>
                            <b>Main Page</b> – where a list of all the builds is
                            presented to the customer.
                        </li>
                        <li>
                            <b>Customisation Page</b> – where the customer can
                            customise the build.
                        </li>
                    </ul>
                </ContentSection>
                <ContentSection
                    container
                    child
                    title='Research'
                    className={styles.research}
                >
                    <Typography>
                        In order to find issues with the pages, Hotjar screen
                        recording feature was used to infer some recurring
                        patterns. After watching more than{' '}
                        <em>400 recordings</em>, a total of{' '}
                        <em>11 recurring issues</em> were identified and divided
                        per page/context.
                    </Typography>
                    <ContentSection
                        child
                        title={'In the Main Page:'}
                        className={styles.context}
                    >
                        <ol>
                            <li>
                                Some users mistaken the page with the
                                ready-to-go one, most likely because of the page
                                title.
                                <p className={styles.how}>
                                    After landing on the page, lots of users
                                    realised that there were not builds ready to
                                    buy and went to the dedicated page instead.
                                </p>
                                <Img
                                    fluid={data.pageTitle.childImageSharp.fluid}
                                    className={styles.img}
                                />
                            </li>
                            <li>
                                Dream PC link usually overlooked or mistaken as
                                the current tool.
                                <p className={styles.how}>
                                    When scrolling past the link, users thought
                                    the banner was there to promote the current
                                    page. After quickly scanning it, they
                                    skipped it immediately.
                                </p>
                                <Img
                                    fluid={data.dreamPc.childImageSharp.fluid}
                                    className={styles.img}
                                />
                            </li>
                            <li>
                                Some users thought that the quick scroll menu on
                                top was supposed to link to different categories
                                of builds.
                                <p className={styles.how}>
                                    After clicking one of them, the page jumps
                                    to the corresponding anchor, leaving users
                                    confused about what just happened. Some
                                    scrolled back to top and clicked it again,
                                    thinking the first time it did not work as
                                    intended.
                                </p>
                                <Img
                                    fluid={
                                        data.quickScrollMenu.childImageSharp
                                            .fluid
                                    }
                                    className={styles.img}
                                />
                            </li>
                            <li>
                                Lots of users expected to be redirected to
                                another tool when clicking on the Dream PC
                                Banner, and got redirected to an intermediary
                                page instead.
                                <p className={styles.how}>
                                    After clicking the banner and been
                                    redirected to the intermediary page,
                                    majority of users scrolled to the bottom
                                    just to learn the page did not contain the
                                    configurator and left immediately.
                                </p>
                                <Img
                                    fluid={
                                        data.dreamPcMiddlePage.childImageSharp
                                            .fluid
                                    }
                                    className={styles.img}
                                />
                            </li>
                        </ol>
                    </ContentSection>
                    <ContentSection
                        child
                        title={'In the Customisation Page:'}
                        className={styles.context}
                    >
                        <ol>
                            <li>
                                While changing parts, users tried to search for
                                more options (using search and filters), not
                                understanding that all the options were already
                                being showed.
                                <p className={styles.how}>
                                    Search and filters function stays active
                                    even when it would be reasonable to disable
                                    them. Some users were observed using them
                                    even in the situation below.
                                </p>
                                <Img
                                    fluid={
                                        data.searchFilters.childImageSharp.fluid
                                    }
                                    className={styles.img}
                                />
                            </li>
                            <li>
                                Changing a selected one-off item will present an
                                empty list.
                                <Img
                                    fluid={data.emptyList.childImageSharp.fluid}
                                    className={styles.img}
                                />
                            </li>
                            <li>
                                Majority removed the limited time offer (even
                                though it was just $0.01), because there is no
                                price indication for the selected product.
                                <p className={styles.how}>
                                    Some users recovered by opening the dropdown
                                    and adding it back after realising that the
                                    option was free. The issue is exacerbated by
                                    the point 2 of this list.
                                </p>
                                <Img
                                    fluid={data.noPrice.childImageSharp.fluid}
                                    className={styles.img}
                                />
                            </li>
                            <li>
                                Some users removed the pre-selected product
                                (when possible) to make the tool display unit
                                price instead of relative-to-selection price.
                                <p className={styles.how}>
                                    Users who used this pattern, removed the
                                    products immediately after switching
                                    attention to them, making clear they
                                    preferred having the unit price.
                                </p>
                                <Img
                                    fluid={
                                        data.relativePrice.childImageSharp.fluid
                                    }
                                    className={styles.img}
                                />
                                <Img
                                    fluid={data.unitPrice.childImageSharp.fluid}
                                    className={styles.img}
                                    style={{
                                        borderTop: '1px solid #888',
                                    }}
                                />
                            </li>
                            <li>
                                Some users tried to use search/filter function
                                while products were still loading, resulting in
                                an unresponsive or delayed action.
                                <p className={styles.how}>
                                    When the action gets delayed, the list is
                                    showed as if displaying the requested data,
                                    although the new action is being processed
                                    and hence the displayed products are stale.
                                </p>
                                <Img
                                    fluid={
                                        data.unresponsiveAction.childImageSharp
                                            .fluid
                                    }
                                    className={styles.img}
                                />
                            </li>
                            <li>
                                The majority of users scrolled the page almost
                                immediately after landing on it, completely
                                ignoring the banner on top.
                                <p className={styles.how}>
                                    This behaviour nullified the help that the
                                    banner could have provided to avoid point 3
                                    of this list.
                                </p>
                            </li>
                            <li>
                                All the mobile users were forced to continuously
                                scroll the page up and down while browsing
                                products.
                                <p className={styles.how}>
                                    Changing page does not scroll back to the
                                    first product of the current page, requiring
                                    users to scroll back up and stop at the
                                    first product. Check{' '}
                                    <a
                                        href='https://stock.adobe.com/au/search?filters%5Bcontent_type%3Aphoto%5D=1&filters%5Bcontent_type%3Aillustration%5D=1&filters%5Bcontent_type%3Azip_vector%5D=1&filters%5Bcontent_type%3Avideo%5D=1&filters%5Bcontent_type%3Atemplate%5D=1&filters%5Bcontent_type%3A3d%5D=1&filters%5Binclude_stock_enterprise%5D=0&filters%5Bis_editorial%5D=0&filters%5Bcontent_type%3Aimage%5D=1&k=behaviour&order=relevance&safe_search=1&search_page=1&search_type=usertyped&limit=100&acp=&get_facets=0'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        aria-label='Open Adobe Stock website to check their pagination'
                                    >
                                        Adobe Stock
                                    </a>{' '}
                                    for a good horizontal pagination
                                    implementation example.
                                </p>
                            </li>
                        </ol>
                    </ContentSection>
                    <ContentSection
                        child
                        title={'Other issues:'}
                        className={styles.context}
                    >
                        <ol>
                            <li>
                                Lack of consistency between delete and remove
                                buttons.
                                <p className={styles.how}>
                                    Difference is present between
                                    multi-selection components and removable
                                    single-selection component.
                                </p>
                                <Img
                                    fluid={
                                        data.buttonConsistency.childImageSharp
                                            .fluid
                                    }
                                    className={styles.img}
                                    style={{ maxWidth: 386 }}
                                />
                            </li>
                            <li>
                                Not using cache when loading products or
                                changing pages.
                                <p className={styles.how}>
                                    The overall speed of the page is quite
                                    impacted by this missing optimisation,
                                    causing every request to be made over the
                                    network, although some ot them might be
                                    cached.
                                </p>
                            </li>
                            <li>
                                Not showing loading indicator when changing page
                                - allowing interaction with stale data.
                                <p className={styles.how}>
                                    Note: this concept applies because of the
                                    horizontal pagination. Check{' '}
                                    <a
                                        href='https://stock.adobe.com/au/search?filters%5Bcontent_type%3Aphoto%5D=1&filters%5Bcontent_type%3Aillustration%5D=1&filters%5Bcontent_type%3Azip_vector%5D=1&filters%5Bcontent_type%3Avideo%5D=1&filters%5Bcontent_type%3Atemplate%5D=1&filters%5Bcontent_type%3A3d%5D=1&filters%5Binclude_stock_enterprise%5D=0&filters%5Bis_editorial%5D=0&filters%5Bcontent_type%3Aimage%5D=1&k=behaviour&order=relevance&safe_search=1&search_page=1&search_type=usertyped&limit=100&acp=&get_facets=0'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        aria-label='Open Adobe Stock website to check their pagination'
                                    >
                                        Adobe Stock
                                    </a>{' '}
                                    for a good horizontal pagination
                                    implementation example.
                                </p>
                            </li>
                            <li>
                                Not showing promo price on products if any.
                                <p className={styles.how}>
                                    It is not clear whether the discount is
                                    being applied or not because the UI is
                                    missing such indication.
                                </p>
                                <Img
                                    fluid={
                                        data.noPromoPrice.childImageSharp.fluid
                                    }
                                    className={styles.img}
                                />
                            </li>
                        </ol>
                    </ContentSection>
                </ContentSection>
                <ContentSection container child title='Engaging'>
                    <Typography>
                        After knowing how users behaved, online surveys were
                        conducted to ask about their experience on the Main
                        page.
                        <br />
                        Four questions were asked through Hotjar polls in the
                        Page:
                    </Typography>
                    <ol>
                        <li>
                            What other information would you like to see on this
                            page?
                        </li>
                        <li>
                            Do you get lost while choosing a build to customise?
                        </li>
                        <li>
                            How would you rate the overall experience on this
                            page?
                        </li>
                        <li>Any other thoughts about this page?</li>
                    </ol>
                    <Typography>
                        The page scored a rating of <em>3.3/5</em> and{' '}
                        <em>7 recurring topics</em> were identified out of{' '}
                        <em>100+ responses</em>:
                    </Typography>
                    <ol>
                        <li>
                            Purpose-based builds request (E.g. audio
                            recording/home studio).
                        </li>
                        <li>A mobile-friendly UI.</li>
                        <li>Cheaper builds.</li>
                        <li>
                            Freedom when choosing parts (no schema for builds).
                        </li>
                        <li>Saving builds to account.</li>
                        <li>Seeking for guidance because of inexperience.</li>
                        <li>A better summary of the build.</li>
                    </ol>
                </ContentSection>
                <ContentSection title='Personas' container child>
                    <Typography style={{ marginBottom: 16 }}>
                        Based on the research findings, 3 prototypes of users
                        were made. Those represent users that might be
                        experiencing the aforementioned issues.
                    </Typography>
                    <PersonasContainer />
                </ContentSection>
                <ContentSection title='Users Journey' container child>
                    <a
                        href={data.sebastian.childImageSharp.fluid.src}
                        target='_blank'
                        rel='noopener noreferrer'
                        aria-label="View Sebastian's user journey on a new tab"
                    >
                        <Img
                            fluid={data.sebastian.childImageSharp.fluid}
                            className={styles.img}
                        />
                    </a>
                    <a
                        href={data.adam.childImageSharp.fluid.src}
                        target='_blank'
                        rel='noopener noreferrer'
                        aria-label="View Adam's user journey on a new tab"
                    >
                        <Img
                            fluid={data.adam.childImageSharp.fluid}
                            className={styles.img}
                        />
                    </a>
                    <a
                        href={data.luan.childImageSharp.fluid.src}
                        target='_blank'
                        rel='noopener noreferrer'
                        aria-label="View Luan's user journey on a new tab"
                    >
                        <Img
                            fluid={data.luan.childImageSharp.fluid}
                            className={styles.img}
                        />
                    </a>
                </ContentSection>
                <ContentSection title='The Revamped Tool' container child>
                    <Typography>
                        After knowing what could be improved and what could be
                        added, main focus was given to the Customisation Page,
                        which contains the most disruptive issues. This means
                        that we will be addressing the <i>Configuring</i> stage
                        of the user journeys above. While trying to address as
                        many issues as possible, some other optimisation and UX
                        subtleties were implemented in order to make the
                        experience as smooth and pleasant as possible.{' '}
                        <a
                            href='https://salvatoresaitta-cpc-live.netlify.com/'
                            target='_blank'
                            rel='noopener noreferrer'
                            aria-label='Open live wireframe'
                        >
                            Check out the live wireframe here
                        </a>
                        .
                    </Typography>
                    <ContentSection
                        title='Shared features across screen sizes:'
                        child
                    >
                        <ol>
                            <li>
                                An all-new dedicated summary to quickly and
                                easily have an overview of the build
                            </li>
                            <li>Dedicated promotions message box</li>
                            <li>Platform-optimised quantity pickers</li>
                            <li>Quick scroll anchors</li>
                            <li>Consistent actions/info</li>
                            <li>Filters/sort hidden when appropriate</li>
                            <li>Clear message about loading more products</li>
                            <li>Products info modal</li>
                            <li>
                                Unit price and relative price (when
                                multi-selection not allowed)
                            </li>
                            <li>Products lazy-loading and caching</li>
                        </ol>
                    </ContentSection>
                    <ContentSection title='Large screen features:' child>
                        <ol>
                            <li>Split layout</li>
                            <li>Tooltips over actions</li>
                            <li>Auto-scroll on Component opening/closing</li>
                            <li>Sticky summary</li>
                            <li>Quick scroll from summary</li>
                            <li>Filters/sort hidden when appropriate</li>
                            <li>Sticky Category heading</li>
                            <li>
                                Sticky Component heading (only when component
                                selection is open)
                            </li>
                            <li>Click blocking when selecting text</li>
                        </ol>
                    </ContentSection>
                    <ContentSection title='Medium screen features:' child>
                        <ol>
                            <li>
                                Points 2, 3, 6, 7 from large screen features
                            </li>
                            <li>Dedicated expandable summary bar</li>
                        </ol>
                    </ContentSection>
                    <ContentSection title='Small screen features:' child>
                        <ol>
                            <li>Point 6 from large screen features</li>
                            <li>App-like look and feel</li>
                            <li>
                                Dedicated transitions between pages (select
                                product and summary)
                            </li>
                            <li>Sticky primary action at the bottom</li>
                            <li>
                                Consistent mobile layout (no page jumps /
                                inconsistencies)
                            </li>
                            <li>Custom optimised filter layout</li>
                        </ol>
                    </ContentSection>
                </ContentSection>
                <ContentSection
                    title="What's next"
                    container
                    child
                    style={{ paddingBottom: 0 }}
                >
                    <Typography>
                        While this project aims to solve issues and improve upon
                        what&apos;s good already, some of the users requests
                        have been left to be handled in the future. Among the
                        others, a guided version of the tool might be a very
                        good addition to the current services offered, as well
                        as being very requested.
                    </Typography>
                    <Typography>
                        Moreover, the entire tool and some UX/UI patterns used
                        throughout the wireframes should be further tested doing
                        another loop of research, in order to understand if we
                        are really solving real users issues and/or improving
                        their experience.
                    </Typography>
                </ContentSection>
            </div>
        </ContentSection>
    )
}

export default CPC

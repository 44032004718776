import React, { ReactElement } from 'react'
import { Grid, LinearProgress, Typography } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import clsx from 'clsx'
import ContentSection from '../../ContentSection/ContentSection'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        photo: {
            marginTop: theme.spacing(2),
        },
        heading: {
            background: '#dfdfdf',
            padding: theme.spacing(1),
            textTransform: 'uppercase',
        },
        resetSpacing: {
            listStyle: 'none',
            marginLeft: 0,
        },
        skills: {
            flex: '0 1 100%',
            display: 'flex',
            flexDirection: 'column',

            '&>li': {
                flex: '0 1 100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            },
        },
    })
)

export type PersonaProps = {
    name: string
    quote: string
    Photo: ReactElement
    bio: string
    age: number
    location: string
    education: string
    job: string
    family: string
    internet: number
    socials: number
    pcs: number
    gaming: number
    onlineShopping: number
    goals: string[]
    frustrations: string[]
}

const Persona: React.FC<PersonaProps> = (props: PersonaProps) => {
    const {
        name,
        quote,
        Photo,
        bio,
        age,
        location,
        education,
        job,
        family,
        internet,
        socials,
        pcs,
        gaming,
        onlineShopping,
        goals,
        frustrations,
    } = props
    const styles = useStyles()

    return (
        <section>
            <Typography variant='h6'>{name}</Typography>
            <Typography
                variant='body2'
                style={{ fontStyle: 'italic', marginTop: 0 }}
            >
                &ldquo;{quote}&rdquo;
            </Typography>
            <div className={styles.photo}>{Photo}</div>
            <ContentSection title='Bio' child>
                <Typography>{bio}</Typography>
            </ContentSection>
            <ContentSection title='Demographics' child>
                <Grid spacing={2} container>
                    <Grid item xs='auto'>
                        <ul className={styles.resetSpacing}>
                            <li>
                                <Typography>Age:</Typography>
                            </li>
                            <li>
                                <Typography>Location:</Typography>
                            </li>
                            <li>
                                <Typography>Education:</Typography>
                            </li>
                            <li>
                                <Typography>Job:</Typography>
                            </li>
                            <li>
                                <Typography>Family:</Typography>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item xs='auto'>
                        <ul className={styles.resetSpacing}>
                            <li>
                                <Typography>{age}</Typography>
                            </li>
                            <li>
                                <Typography>{location}</Typography>
                            </li>
                            <li>
                                <Typography>{education}</Typography>
                            </li>
                            <li>
                                <Typography>{job}</Typography>
                            </li>
                            <li>
                                <Typography>{family}</Typography>
                            </li>
                        </ul>
                    </Grid>
                </Grid>
            </ContentSection>
            <ContentSection title='Skills' child>
                <Grid spacing={2} container>
                    <Grid item xs='auto'>
                        <ul className={styles.resetSpacing}>
                            <li>
                                <Typography>Internet:</Typography>
                            </li>
                            <li>
                                <Typography>Social Networks:</Typography>
                            </li>
                            <li>
                                <Typography>PCs:</Typography>
                            </li>
                            <li>
                                <Typography>Gaming:</Typography>
                            </li>
                            <li>
                                <Typography>Online Shopping:</Typography>
                            </li>
                        </ul>
                    </Grid>
                    <Grid
                        item
                        xs
                        style={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <ul
                            className={clsx(styles.resetSpacing, styles.skills)}
                        >
                            <li>
                                <LinearProgress
                                    variant='determinate'
                                    value={internet}
                                />
                            </li>
                            <li>
                                <LinearProgress
                                    variant='determinate'
                                    value={socials}
                                />
                            </li>
                            <li>
                                <LinearProgress
                                    variant='determinate'
                                    value={pcs}
                                />
                            </li>
                            <li>
                                <LinearProgress
                                    variant='determinate'
                                    value={gaming}
                                />
                            </li>
                            <li>
                                <LinearProgress
                                    variant='determinate'
                                    value={onlineShopping}
                                />
                            </li>
                        </ul>
                    </Grid>
                </Grid>
            </ContentSection>
            <ContentSection title='Goals' child>
                <ul>
                    {goals.map((g, i) => (
                        <li key={i}>{g}</li>
                    ))}
                </ul>
            </ContentSection>
            <ContentSection title='Frustrations' child>
                <ul>
                    {frustrations.map((f, i) => (
                        <li key={i}>{f}</li>
                    ))}
                </ul>
            </ContentSection>
        </section>
    )
}

export default Persona

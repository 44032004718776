import React from 'react'
import { Grid } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Persona from './Persona'

const useStyles = makeStyles(() =>
    createStyles({
        personaContainer: {
            // maxWidth: 400,
        },
        photo: {
            maxWidth: 300,
            borderRadius: '100%',
        },
    })
)

const PersonasContainer: React.FC = () => {
    const styles = useStyles()

    const data = useStaticQuery(graphql`
        {
            sebastian: file(
                relativePath: { eq: "images/cpc/sebastian-photo.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                        aspectRatio
                        src
                        srcSet
                        sizes
                        base64
                        srcWebp
                        srcSetWebp
                    }
                }
            }
            adam: file(relativePath: { eq: "images/cpc/adam-photo.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                        aspectRatio
                        src
                        srcSet
                        sizes
                        base64
                        srcWebp
                        srcSetWebp
                    }
                }
            }
            luan: file(relativePath: { eq: "images/cpc/luan-photo.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                        aspectRatio
                        src
                        srcSet
                        sizes
                        base64
                        srcWebp
                        srcSetWebp
                    }
                }
            }
        }
    `)

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={4} className={styles.personaContainer}>
                <Persona
                    name='Sebastian Goddard'
                    quote='I love gaming PCs and I would like them to be cheaper!'
                    Photo={
                        <Img
                            fluid={data.sebastian.childImageSharp.fluid}
                            alt='Sebastian Photo'
                            className={styles.photo}
                        />
                    }
                    bio='Sebastian is a 16-year-old boy who is still at school and live with his parents. He likes gaming and is well informed on the latest technologies. He depends on his parents and his part-time job for his online shopping.'
                    age={16}
                    location='Rose Bay, NSW'
                    education='Primary School'
                    job='Student'
                    family='Living with parents'
                    internet={69}
                    socials={85}
                    pcs={30}
                    gaming={90}
                    onlineShopping={65}
                    goals={[
                        'Share builds with his friends',
                        'Buy his dream pc',
                    ]}
                    frustrations={[
                        'Not having enough money',
                        'Not sure if components will be compatible',
                        'Having to choose between to many builds',
                        'Not being guided throughout the process',
                    ]}
                />
            </Grid>
            <Grid item xs={12} md={4} className={styles.personaContainer}>
                <Persona
                    name='Adam Boismenu'
                    quote='I want to configure a PC which is a good fit without spending a fortune!'
                    Photo={
                        <Img
                            fluid={data.adam.childImageSharp.fluid}
                            alt='Adam Photo'
                            className={styles.photo}
                        />
                    }
                    bio='Adam is a 29-year-old software engineer who is working on different projects with different contractors. He likes to play videogames sometimes but generally spend his spare doing other things.'
                    age={29}
                    location='Enfield, NSW'
                    education='BA Computer Science'
                    job='Software engineer'
                    family='Single'
                    internet={82}
                    socials={70}
                    pcs={85}
                    gaming={43}
                    onlineShopping={78}
                    goals={[
                        'Buy a PC with a good price/quality ratio',
                        'Configure the build through his mobile',
                    ]}
                    frustrations={[
                        'Gets upset when a website is slow or not working as intended',
                        'Not sure about warranty terms and conditions',
                        'Not being able to freely customise a build',
                        'Would browse from smartphone, but finds the experience not satisfying',
                    ]}
                />
            </Grid>
            <Grid item xs={12} md={4} className={styles.personaContainer}>
                <Persona
                    name='Luan Almeida'
                    quote='I like when my purchase journey is clear and well informed.'
                    Photo={
                        <Img
                            fluid={data.luan.childImageSharp.fluid}
                            alt='Luan Photo'
                            className={styles.photo}
                        />
                    }
                    bio='Luan is a 44-year-old business owner and he does not know much about custom PCs. However he has a PC and uses internet on a regular basis. He usually need some advice when buying technology to make sure the purchase is a good fit for his business.'
                    age={44}
                    location='Neutral Bay, NSW'
                    education='Bachelor of Business'
                    job='Self-employed'
                    family='Married with 1 child'
                    internet={50}
                    socials={41}
                    pcs={35}
                    gaming={10}
                    onlineShopping={20}
                    goals={[
                        'Get more literate to make an informed purchase',
                        'Renovate PCs for his business',
                    ]}
                    frustrations={[
                        'Gets confused when trying to use complicated tools',
                        'Depending on someone else for his purchases',
                        'Spending too much for technology-related products',
                        'Not being guided throuout the process',
                    ]}
                />
            </Grid>
        </Grid>
    )
}

export default PersonasContainer

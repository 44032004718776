import React from 'react'

import SEO from '../../components/SEO/SEO'
import CPC from '../../components/Projects/CPC/CPC'
import Transition from '../../components/Transition/Transition'

const CPCPage: React.FC = () => (
    <Transition>
        <SEO title='Projects' />
        <CPC />
    </Transition>
)

export default CPCPage
